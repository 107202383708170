<template>
  <div v-if="!isLoading">
    <b-alert v-if="isSaved" variant="success" show class="mb-3">Package products were successfully updated</b-alert>
    <table class="table table-hover">
      <thead>
        <th>Product</th>
        <th>Value (Monthly)</th>
        <th>Upgradable</th>
        <th>Downgradable</th>
        <th class="text-right">Enabled</th>
      </thead>
      <tbody>
        <tr v-for="(item, key) in products" :key="'p-'+key" :class="item.enabled ? '' : 'bg-light text-muted'">
          <td>{{item.name}}</td>
          <td>
            <b-form-input v-if="item.amount !== null" v-model="item.amount" size="sm" placeholder="enter amount" :disabled="!item.enabled"></b-form-input>
            <span v-else>-</span>
          </td>
          <td>
            <b-form-checkbox v-if="item.upgradable" v-model="item.pkpUpgradable" :disabled="!item.enabled" size="lg" switch/>
            <span v-else>-</span>
          </td>
          <td>
            <b-form-checkbox v-if="item.downgradable" v-model="item.pkpDowngradable" :disabled="!item.enabled" size="lg" switch/>
            <span v-else>-</span>
          </td>
          <td class="text-right">
            <b-form-checkbox v-model="item.enabled" size="lg" switch/>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="border p-3 bg-light mb-3">
      "0" = infinite<br>
      "-" = unapplicable<br>
    </div>
    <b-button :disabled="isSaving" variant="primary" @click="save()">
      <span v-if="!isSaving">Save</span>
      <b-spinner v-else small></b-spinner>
    </b-button>
  </div>
  <b-spinner v-else label="Loading..." small></b-spinner>
</template>

<script>
export default {
  props: ['pk'],
    components: {

    },
    data(){
      return {
        isSaved: false,
        isSaving: false,
        isLoading: false,
        products: [],
      }
    },

    methods: {

      parseProduct(item){
        let pkProduct = this.pk.products.find(o => o.id === item.id);
        item.enabled = false;
        item.pkpUpgradable = false;
        item.pkpDowngradable = false;

        if(pkProduct){
          item.enabled = true;
          item.amount = pkProduct.amount;
          if(item.upgradable){
            item.pkpUpgradable = pkProduct.upgradable;
          }
          if(item.downgradable){
            item.pkpDowngradable = pkProduct.downgradable;
          }
        }

        return item;
      },

      getProducts({pageNo},filters){
        this.isLoading = true;
        this.$api.get('products',{
          params: Object.assign({
            perPage: 50,
            page: pageNo,
          },filters || {})
        }).then(({data}) => {
          this.isLoading = false;
          this.products = data.records.map(this.parseProduct);
        });
      },

      getProduct(productId){
        return this.products.find(o => o.id === productId);
      },

      save(){
        this.isSaving = true;
        let enabled = this.products.filter(o => o.enabled).map(item => {
          return {
            id: item.id,
            amount: item.amount,
            upgradable: item.pkpUpgradable,
            downgradable: item.pkpDowngradable,
          }
        });
        this.$api.put(`products/package/${this.pk.id}/products`,{
          data: {products: enabled}
        })
        .then(({data}) => {
          this.isSaving = false;
          this.isSaved = true;
          this.$emit("onPackageUpdate", data);
          document.querySelector(".page-with-menu").scrollTop = 0;
          setTimeout(() => {
            this.isSaved = false;
          },3000)
        });
      }
    },
    mounted(){
      this.getProducts({pageNo: 1},{status: "active"});
      this.$emit('set-sidebar',{active: "products"});
    },

};
</script>
